export default async function (props) {
    return Promise.all(
        Object.values(import.meta.glob([ '@/modules/**/*Init.ts', ]))
            .map(async (module) => {
                const { init: initFn, } = await module()
                initFn(props)
                return Promise.resolve()
            })
    )
}
