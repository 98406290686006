import type { ToastRootProps, } from 'radix-vue'
import type { HTMLAttributes, } from 'vue'

import { type VariantProps, cva, } from 'class-variance-authority'

export { default as Toaster, } from './Toaster.vue'
export { default as Toast, } from './Toast.vue'
export { default as ToastViewport, } from './ToastViewport.vue'
export { default as ToastAction, } from './ToastAction.vue'
export { default as ToastClose, } from './ToastClose.vue'
export { default as ToastTitle, } from './ToastTitle.vue'
export { default as ToastDescription, } from './ToastDescription.vue'
export { default as ToastProvider, } from './ToastProvider.vue'
export { toast, useToast, } from './use-toast'

export const toastVariants = cva(
    'group pointer-events-auto relative flex w-full items-center justify-between space-x-4 overflow-hidden rounded-md border p-6 pr-8 shadow-lg transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[--radix-toast-swipe-end-x] data-[swipe=move]:translate-x-[--radix-toast-swipe-move-x] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full',
    {
        variants: {
            variant: {
                default: 'border bg-background text-foreground',
                primary: 'group border-primary bg-primary text-primary-foreground',
                destructive: 'group border-destructive bg-destructive text-destructive-foreground',
                success: 'group border-success bg-success text-success-foreground',
                warning: 'group border-warning bg-warning text-warning-foreground',
                info: 'group border-accent bg-accent text-accent-foreground',
            },
        },
        defaultVariants: {
            variant: 'default',
        },
    }
)

type ToastVariants = VariantProps<typeof toastVariants>

export interface ToastProps extends ToastRootProps {
    class?: HTMLAttributes['class']
    variant?: ToastVariants['variant']
    onOpenChange?: ((value: boolean) => void) | undefined
}
